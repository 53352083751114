import React, { useState, useEffect } from 'react';
import styles from './VerticalSlider.module.scss';

const VerticalSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const tabs = ['Expertise', 'Benchmark', 'Insights', 'Know Us', 'Partners'];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const offsets = tabs.map((tab) => {
        const section = document.getElementById(tab);
        return section ? section.offsetTop : 0;
      });

      const currentIndex = offsets.findIndex((offset, index) => {
        return scrollPosition < offsets[index + 1] && scrollPosition >= offset;
      });

      if (currentIndex !== -1 && currentIndex !== activeIndex) {
        setActiveIndex(currentIndex);
      } else if (scrollPosition >= document.body.scrollHeight) {
        setActiveIndex(tabs.length - 1); // Special case for the last section
      } else if (scrollPosition < offsets[1]) {
        setActiveIndex(0); // Special case for the first section
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeIndex, tabs]);

  const handleTabClick = (index) => {
    setActiveIndex(index);
    document.getElementById(tabs[index]).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className={styles.verticalSlider}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${styles.sliderTab} ${
            index === activeIndex ? styles.active : ''
          }`}
          onClick={() => handleTabClick(index)}
        >
          <span className={styles.tabLabel}>{tab}</span>
          <div
            className={`${styles.dot} ${
              index === activeIndex ? styles.active : ''
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default VerticalSlider;
