import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Button, Toast } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomModal from './../customModal/CustomModal';
import styles from './../customModal/CustomModal.module.scss';
import emailjs from '@emailjs/browser';

const ContactFormPopup = ({ show, handleClose }) => {
  const [success, setSuccess] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [selectedServices, setSelectedServices] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const form = useRef(null);

  useEffect(() => {
    if (show && form.current) {
      const defaultService = form.current.querySelector('select[name="service"] option:not([disabled])').value;
      setSelectedServices(defaultService);
    }
  }, [show]);

  const sendEmail = (e) => {
    e.preventDefault();

    if (phoneError || mobileNumber.length < 12) {
      setPhoneError('Please enter exactly 10 digits.');
      return; // Prevent form submission if there's a phone number error
    }

    const formData = new FormData(form.current);
    formData.set('Services', selectedServices);
    formData.set('Mobile', mobileNumber); // Ensure the mobile number is correctly set
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    emailjs
      .sendForm(
        'service_n47l8uo', // Replace with your actual service ID
        'template_s4q8zkv', // Replace with your actual template ID
        form.current,
        '3QBAxDcBTZkwDZ5zX' // Replace with your actual public key
      )
      .then(
        () => {
          setSuccess('');
          form.current.reset();
          setSelectedServices('');
          setMobileNumber('');
          setShowToast(true);
          handleClose();

          setTimeout(() => {
            setShowToast(false);
            setSuccess(null);
          }, 1500);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setSuccess('Failed to send the message. Please try again later.');
        }
      );
  };


  const handleServiceChange = (event) => {
    const { value } = event.target;
    setSelectedServices(value);
  };

  const handlePhoneChange = (value) => {
    // Remove any non-numeric characters (e.g., country code, spaces)
    const cleanedValue = value.replace(/\D/g, '');
    setMobileNumber(cleanedValue);

    if (cleanedValue.length < 12) {
      setPhoneError('Please enter exactly 10 digits.');
    } else {
      setPhoneError('');
    }
  };

  return (
    <>
      <CustomModal show={show} handleClose={handleClose}>
        <div className={styles.modalContent}>
          <Row>
            <Col md={5} className="align-items-center">
              <img src={require('./../../assets/img/chat.png')} className="img-fluid" alt="About us" />
              <h3 className="gradient-text">We look forward to helping you</h3>
              <p>
                Please, send us a message or give us a call. We are looking forward to hearing from you and would like
                to know how we can help you.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              <form ref={form} onSubmit={sendEmail}>
                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Name</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src={require('./../../assets/img/form-name.png')} className="img-fluid" alt="About us" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Name"
                    required
                    aria-label="name"
                    aria-describedby="basic-addon1"
                    name="Name"
                  />
                </InputGroup>
                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Email</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img src={require('./../../assets/img/form-email.png')} className="img-fluid" alt="About us" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Email"
                    required
                    type="email"
                    aria-label="email"
                    aria-describedby="basic-addon1"
                    name="Email"
                  />
                </InputGroup>
                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Mobile</Form.Label>
                <InputGroup className="mb-3">
                  <PhoneInput
                    country={'in'}
                    placeholder="Mobile"
                    required
                    aria-label="Mobile"
                    aria-describedby="basic-addon1"
                    name="MobileDisplay" // Rename to avoid duplication in formData
                    inputStyle={{ width: '100%', height: '100%' }}
                    onChange={handlePhoneChange}
                    value={mobileNumber}
                  />
                  <input type="hidden" name="Mobile" value={mobileNumber} />
                </InputGroup>
                {phoneError && <p className="text-danger">{phoneError}</p>}

                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Budget</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select aria-label="budget" name="Budget" required>
                    <option value="" disabled>Select your budget</option>
                    <option value="Under ₹20,000">Under ₹20,000</option>
                    <option value="₹20,000 - ₹50,000">₹20,000 - ₹50,000</option>
                    <option value="₹50,000 - ₹100,000">₹50,000 - ₹100,000</option>
                    <option value="Above ₹100,000">Above ₹100,000</option>
                  </Form.Select>
                </InputGroup>

                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Service</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select aria-label="service" name="service" onChange={handleServiceChange} required>
                    <option value="" disabled>Select a service</option>
                    <option value="3D & Augmented Reality (AR)">3D & Augmented Reality (AR)</option>
                    <option value="Performance Marketing">Performance Marketing</option>
                    <option value="Social Media Management">Social Media Management</option>
                    <option value="Customized Software Solutions (CRM/ERP)">Customized Software Solutions (CRM/ERP)</option>
                    <option value="Application & Web Development">Application & Web Development</option>
                    <option value="Automation Services">Automation Services</option>
                  </Form.Select>
                </InputGroup>
                <input type="hidden" name="Services" value={selectedServices} />

                <Form.Label htmlFor="basic-url" className={styles['form-label']}>Message</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Tell us about your project"
                    aria-label="message"
                    required
                    as="textarea"
                    aria-describedby="basic-addon1"
                    name="Message"
                  />
                </InputGroup>
                <div className="d-grid mt-3 gap-2">
                  <Button className={styles['btn-primary']} type="submit" size="lg">
                    Submit
                  </Button>
                </div>
                {success && <p className={styles.successMessage}>{success}</p>}
              </form>
            </Col>
          </Row>
        </div>
      </CustomModal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={5000}
        autohide
        style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '9999' }}
      >
        <Toast.Header>
          <strong className="me-auto">Submission Success</strong>
        </Toast.Header>
        <Toast.Body>Thank you for your submission! We appreciate your effort and will get back to you shortly.</Toast.Body>
      </Toast>
    </>
  );
};

export default ContactFormPopup;
