import styles from "./Technologies.module.scss";

import android from "./../../assets/img/technologies/android.png";
import ios from "./../../assets/img/technologies/ios.png";
import unity from "./../../assets/img/technologies/unity.png";
import flutter from "./../../assets/img/technologies/flutter.png";
import kotlin from "./../../assets/img/technologies/kotlin.png";
import java from "./../../assets/img/technologies/java.png";
import angular from "./../../assets/img/technologies/angular.png";
import react from "./../../assets/img/technologies/react.png";
import node from "./../../assets/img/technologies/node.png";
import aws from "./../../assets/img/technologies/aws.png";
import gcloud from "./../../assets/img/technologies/gcloud.png";
import graphql from "./../../assets/img/technologies/graphql.png";
import firebase from "./../../assets/img/technologies/firebase.png";
import saas from "./../../assets/img/technologies/saas.png";
import wordpress from "./../../assets/img/technologies/wordpress.png";
import azure from "./../../assets/img/technologies/azure.png";
import js from "./../../assets/img/technologies/js.png";
import mysql from "./../../assets/img/technologies/mysql.png";
import mongodb from "./../../assets/img/technologies/mongodb.png";
import React from "react";

const techIcons = [
  android,
  ios,
  unity,
  flutter,
  kotlin,
  java,
  angular,
  react,
  node,
  aws,
  gcloud,
  graphql, 
  firebase,
  saas,
  wordpress,
  azure,
  js, 
  mysql,
  mongodb
];

const Technologies = () => {
  return (
    <>
    <section className={styles.technologiesSection}>
      <div className={styles.content}>
        <h1 className="gradient-text font-weight-light px-3">Technologies We Are Using</h1>
        <div className={styles.techIcons}>
          {techIcons.map((icon, index) => (
            <div key={index} className={styles.techIcon}>
              <img src={icon} alt={`technology-${index}`} />
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

export default Technologies;
