import React, { useEffect, useRef } from 'react';
import styles from './Services.module.scss';

const Services = () => {
  const serviceRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null, // viewport
      threshold: 0.1, // 10% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const serviceDetail = entry.target;
        const image = serviceDetail.querySelector(`.${styles.serviceImage}`);
        const content = serviceDetail.querySelector(`.${styles.serviceContent}`);

        if (entry.isIntersecting) {
          serviceDetail.classList.add(styles.visible);
          image.classList.add(styles.visible);
          content.classList.add(styles.visible);

          // Add the right class for text animation
          if (serviceDetail.classList.contains(styles.right)) {
            content.classList.add(styles.right);
          }
        } else {
          // Remove classes when out of view to reset the animation
          serviceDetail.classList.remove(styles.visible);
          image.classList.remove(styles.visible);
          content.classList.remove(styles.visible);

          // Remove the right class for text animation
          if (serviceDetail.classList.contains(styles.right)) {
            content.classList.remove(styles.right);
          }
        }
      });
    }, options);

    serviceRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      if (serviceRefs.current) {
        serviceRefs.current.forEach((ref) => observer.unobserve(ref));
      }
    };
  }, []);

  return (
    <div className={styles.servicesSection}>
      <div className={styles.introSection}>
        <h3 className="gradient-text font-weight-light">Explore Our Capabilities</h3>
      </div>

      <div className={styles.serviceDetailSection}>
        {[
          {
            title: '3D & Augmented Reality (AR)',
            content: `Reimagine your brand’s impact with 3D & Augmented Reality experiences that captivate and inspire. Every detail, every interaction, designed to transport your audience into new dimensions. We fuse technology with imagination, turning concepts into breathtaking realities. Ready to push the boundaries? Let’s make your brand a visionary experience.`,
            buttons: ['AR Experiences', '3D Modeling', 'Virtual Showrooms'],
            video: require('./../../assets/videos/3D.mp4'),
            align: 'left'
          },
          {
            title: 'Performance Marketing',
            content: `Elevate your brand’s potential with performance marketing that delivers results. Every click, every conversion, crafted to perfection. We transform data into growth, driving your success with precision and creativity. Ready to maximize your ROI? Let’s turn your marketing into a powerhouse of performance.`,
            buttons: ['Meta Ads', 'Google Ads', 'Conversion Optimization'],
            video: require('./../../assets/videos/MetaAds.mp4'),
            align: 'right'
          },
          {
            title: 'Social Media Management',
            content: `Transform your brand's story into a social media sensation that resonates and inspires. Every post, every campaign, crafted to spark connections and ignite conversations. We blend strategy with creativity, turning followers into loyal advocates. Ready to create a social media legacy? Let’s make your brand unforgettable in the digital world.`,
            buttons: ['Content Creation', 'Social Media Strategy'],
            video: require('./../../assets/videos/SocialMedia.mp4'),
            align: 'left'
          },
          {
            title: 'Customized Software Solutions (CRM/ERP)',
            content: `Craft your business's future with customized software solutions tailored to your unique needs. Every line of code, every feature, designed to empower and innovate. We fuse functionality with imagination, transforming challenges into cutting-edge solutions. Ready to redefine what's possible? Let’s build software that’s as unique as your vision.`,
            buttons: ['CRM Development', 'ERP Solutions', 'Custom Integrations'],
            video: require('./../../assets/videos/WebDev.mp4'),
            align: 'right'
          },
          {
            title: 'Application & Web Development',
            content: `Design your digital future with app & web development that’s as visionary as your brand. Every pixel, every feature, meticulously crafted to captivate and engage. We blend innovation with artistry, turning ideas into seamless, powerful experiences. Ready to disrupt the digital landscape? Let’s create apps and websites that redefine excellence.`,
            buttons: ['Website Development', 'Mobile App Development'],
            video: require('./../../assets/videos/AppDev.mp4'),
            align: 'left'
          },
          {
            title: 'Automation Services',
            content: `Revolutionize your workflow with automation services that think ahead. Every process, every integration, engineered to optimize and elevate. We merge intelligence with efficiency, transforming routine tasks into seamless, automated brilliance. Ready to free your time and amplify your impact? Let’s automate your success, one smart solution at a time.`,
            buttons: ['Cloud Infrastructure', 'Data Management', 'Cloud Security'],
            video: require('./../../assets/videos/Analytics.mp4'),
            align: 'right'
          },
        ].map((service, index) => (
          <div
            key={index}
            ref={el => serviceRefs.current[index] = el}
            className={`${styles.serviceDetail} ${styles[service.align]}`}
          >
            <div className={styles.serviceImage}>
              <video autoPlay loop muted className={styles.backgroundVideo}>
                <source src={service.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className={styles.serviceContent}>
              <h3>{service.title}</h3>
              <p>{service.content}</p>
              <div className={styles.serviceButtons}>
                {service.buttons.map((button, i) => (
                  <button key={i}>{button}</button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
