import React, { useState, useRef, useEffect } from 'react';
import './assets/sass/Home.scss';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VerticalSlider from './components/VerticalSlider/VerticalSlider';
import ContactFormPopup from './components/QueryForm/QueryForm';
import Services from './components/Services/Services';
import Technologies from './components/Technologies/Technologies';
;
function Home() {
  const [showPopup, setShowPopup] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };
  useEffect(() => {
    // Set a timer to show the popup after 1 minute (60000 ms)
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 60000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleOpenPopup = () => {
    setShowPopup(true);
  };
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 600,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };





  return (
    <>
      <ContactFormPopup show={showPopup} handleClose={handleClosePopup} />
      <div className="App" id="Home">
        {/* <Scene/> */}
        <VerticalSlider />

        <div className="banner">
          <Container className="text-center">
            <h3 className="headline" style={{ color: '#fff' }}>
              one stop <span className="gradient-text">Solution</span> for all your <span className="gradient-text">Business</span> needs..
            </h3>
            <Button
              onClick={() => window.open("https://drive.google.com/drive/folders/1kQ7rEpIsUALzw1tGz3QU6XGQ-C_hUoC9", "_blank")}
              className="btn-consult mt-4"
            >
              Explore Our Portfolio
            </Button>
          </Container>
        </div>
        <div id="Expertise" style={{ textAlign: 'center' }}>
          <Services />
          <Button onClick={handleOpenPopup} className="btn-consult mt-4">
            Want to discuss? Let’s Schedule a Call
          </Button>
        </div>
        <div className="sets-us-apart" id="Benchmark">
          <Container className="text-center">
            <h3 className="gradient-text font-weight-light" style={{ marginBottom: '2.5rem' }}>
              Benchmark
            </h3>
            <Row className="mt-5">
              <Col md={4} className="align-items-center trans-col">
                <video autoPlay loop muted className="bulbVideo">
                  <source src={require('./assets/videos/Bulb.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>Innovative Solutions</h4>
                <p>
                  Fueling innovation in every service, we craft solutions that redefine norms, offering customers groundbreaking experiences that stand as a testament to our ingenuity.
                </p>
              </Col>
              <Col md={4} className="align-items-center trans-col">
                <video autoPlay loop muted className="supportVideo">
                  <source src={require('./assets/videos/24x7.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>24/7 Support</h4>
                <p>
                  Our round-the-clock support ensures that our customers receive assistance whenever they need it, guaranteeing satisfaction and peace of mind.
                </p>
              </Col>
              <Col md={4} className="align-items-center trans-col">
                <video autoPlay loop muted className="teamVideo">
                  <source src={require('./assets/videos/Team.mp4')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <h4>Expert Team</h4>
                <p>
                  With a diverse blend of expertise and a shared passion for excellence, our team at DevInfinity consistently delivers unparalleled service, setting a gold standard in customer satisfaction.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="customers-visit" id="Insights">
          <Container className="text-center" style={{ marginBottom: '2.5rem' }}>
            <h3 className="gradient-text font-weight-light">Insights</h3>
          </Container>
          <div className='container'>
            <div className="insights">
              <video autoPlay loop muted className="percentVideo">
                <source src={require('./assets/videos/Percent.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>Our circle of happy clients keeps growing</p>
            </div>
            <div className="insights">
              <video autoPlay loop muted className="graphVideo">
                <source src={require('./assets/videos/Graph.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>Consistent, strong, and ever upward</p>
            </div>
            <div className="insights">
              <video autoPlay loop muted className="starVideo">
                <source src={require('./assets/videos/Star.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>A solid rating reflecting commitment</p>
            </div>
          </div>
        </div>
        <div id="Tools">
          <Technologies />
        </div>

        <div className="about-us" id="Know Us">
          <Container>
            <Row>
              <Col md={6} className="align-items-center" style={{ marginBottom: '20px' }}>
                <h3 className="gradient-text font-weight-light">Know Us</h3>
                <p className="px-3">
                  DevInfinity Inc. was born in 2022, but our story began in 2016 when two ambitious engineers set out to transform ideas into reality. While still in college, we dived into the world of technology, specializing in CRM, web, and app development. Our passion and dedication quickly paid off—within just one month of launching DevInfinity, we secured our first client, setting the foundation for our journey.

                  At DevInfinity, we believe in pushing the boundaries of what’s possible. We’ve set the bar high with our unparalleled support and top-notch services in the tech industry. Fast forward to 2024, and we’re at the forefront of Augmented Reality, bringing cutting-edge solutions to sectors like fashion and hospitality.

                  Our journey is driven by innovation, and we’re excited to shape the future with technology that goes beyond imagination.

                  Join us as we create the next generation of digital experiences.
                </p>
                <Button onClick={handleOpenVideo} className="mt-3">
                  Know More
                </Button>
              </Col>
              <Col md={6} className="align-content-center">
                <img src={require('./assets/img/about.png')} className="img-fluid" alt="About us" />
              </Col>
            </Row>
          </Container>

          {/* Video Popup */}
          {isVideoOpen && (
            <div className="video-popup-overlay" onClick={handleCloseVideo}>
              <div className="video-popup-content">
                <iframe
                  src="https://www.youtube.com/embed/wi43FSeLD0I"
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>

        <div className="trusted-by" id="Partners">
          <Container className="text-center">
            <h3 className="gradient-text font-weight-light">Trusted by Leading Organizations</h3>
            <div className="mt-4">
              <Slider {...settings} adaptiveHeight="true" >
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/guvi.png')} width='100%' alt="Guvi" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/hawmi.png')} width='100%' alt="Hawmi" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/farmididi.png')} width='100%' alt="Farmdidi" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/knmn.png')} width='100%' alt="KNMN" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/reef-dwellings.png')} width='100%' alt="Reef Dwellings" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/Rimberio.png')} width='100%' alt="Rimberio" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/SOL.png')} width='100%' alt="SOL" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/StroxTech.png')} width='100%' alt="Strox Tech" />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={require('./assets/img/yak&yeti.png')} width='100%' alt="Yak and Yeti" />
                </div>
              </Slider>
            </div>
          </Container>
        </div>

      </div>
    </>
  );
}

export default Home;
