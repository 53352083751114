import React, { useState } from 'react';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { ReactComponent as Logo } from './../../assets/svg/logo.svg';
import ScrollToTop from "../../theme/ScrollToTop";
import styles from './Header.module.scss';
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleScrollLinkClick = (section) => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        scroller.scrollTo(section, {
          smooth: true,
          duration: 500,
          offset: -70, // Offset for sticky header, adjust as needed
        });
      }, 100); // Delay to ensure the navigation completes before scrolling
    } else {
      scroller.scrollTo(section, {
        smooth: true,
        duration: 500,
        offset: -70,
      });
    }
    toggleMenu(); // Close the menu after clicking
  };

  return (
    <>
      <ScrollToTop />
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <Link to="/">
              <Logo className={styles.animatedLogo} />
            </Link>
          </div>
          <button
            className={`${styles.hamburger} ${menuActive ? styles.active : ''}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <nav className={`${styles.navbar} ${menuActive ? styles.active : ''}`}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <span onClick={() => handleScrollLinkClick("Home")} className={styles.navLink}>
                  Home
                </span>
              </li>
              <li className={`${styles.navItem} ${styles.mobileViewOnly}`}>
                <span onClick={() => handleScrollLinkClick("Know Us")} className={styles.navLink}>
                Know Us
                </span>
              </li>
              <li className={styles.navItem}>
                <span onClick={() => handleScrollLinkClick("Expertise")} className={styles.navLink}>
                  Expertise
                </span>
              </li>
              <li className={styles.navItem}>
                <span onClick={() => handleScrollLinkClick("Partners")} className={styles.navLink}>
                  Partners
                </span>
              </li>
              <li className={styles.navItem}>
                <span onClick={() => handleScrollLinkClick("Insights")} className={styles.navLink}>
                  Insights
                </span>
              </li>
              <li className={`${styles.navItem} ${styles.mobileViewOnly}`}>
                <span onClick={() => handleScrollLinkClick("Benchmark")} className={styles.navLink}>
                  Benchmark
                </span>
              </li>
            </ul>
          </nav>
          <div className={styles.consultButtonContainer}>
            <a href="https://wa.me/7042352490?text=Hi" target="_blank" rel="noreferrer" className={styles.consultButton}>
              Chat Now <img src={require('./../../assets/img/arrow-up-white.png')} alt="consult" />
            </a>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
