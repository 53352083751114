import React from 'react';
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import './Footer.scss';
import { ReactComponent as Logo } from './../../assets/svg/logo.svg';

function Footer(props) {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row copyrights'>
                    <div className='col d-flex justify-content-center align-items-center'>
                        <Link to="/"><Logo className='logo' /></Link>
                    </div>
                    {/* <div className='col col-md-3'>
                        <h5><a href="/#services">Services</a></h5>
                        <ul>
                            <li><a href="/#services">Mobile App / Web Development</a></li>
                            <li><a href="/#services">SEO / Facebook & Google Ads and LinkedIn Marketing</a></li>
                            <li><a href="/#services">Content Creation & Social Media Branding</a></li>
                            <li><a href="/#services">3D Animation</a></li>
                            <li><a href="/#services">Enterprise Resource Planning</a></li>
                            <li><a href="/#services">Business Consulting</a></li>
                        </ul>
                    </div> */}
                    {/* <div className='col col-md-3'>
                        <h5>Company</h5>
                        <ul>
                            <li><a href="/#about">About</a></li>
                            <li><a href="https://wa.me/7042352490?text=Hi" target='_blank' rel="noreferrer">Contact</a></li>
                            <li><a href="/#testimonials">Testimonial</a></li>
                        </ul>
                    </div> */}
                    {/* <div className='col col-md-3'>
                        <h5>Quick Links</h5>
                        <ul>
                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                        </ul>
                        <div className='social-icons'>
                            <a href='https://www.instagram.com/devinfinity.inc?igsh=M2IzMHc3aW1vemdj' target='_blank' rel="noreferrer"><img src={require('./../assets/img/instagram.png')} alt="Instagram" className="img-fluid"/></a>
                            <a href='https://www.linkedin.com/' target='_blank' rel="noreferrer"><img src={require('./../assets/img/linkedin.png')} alt="LinkedIn" className="img-fluid"/></a>
                            <a href='https://www.facebook.com/profile.php?id=61560705150314&mibextid=ZbWKwL' target='_blank' rel="noreferrer"><img src={require('./../assets/img/facebook.png')} alt="Facebook" className="img-fluid"/></a>
                        </div>
                    </div> */}
                </div>
                <div className='row copyrights'>
                    <div className='col col-md-4 c1'>
                    <a href='mailTo:connect@devinfinityinc.com' target='_blank' rel="noreferrer"><img src={require('./../../assets/img/mail.png')} alt="LinkedIn" className="img-fluid"/></a>
                    <a href='https://www.instagram.com/devinfinity.inc?igsh=M2IzMHc3aW1vemdj' target='_blank' rel="noreferrer"><img src={require('./../../assets/img/instagram.png')} alt="Mail" className="img-fluid"/></a>
                    
                    {/* <a href='https://www.facebook.com/profile.php?id=61560705150314&mibextid=ZbWKwL' target='_blank' rel="noreferrer"><img src={require('./../assets/img/facebook.png')} alt="Facebook" className="img-fluid"/></a> */}
                    </div>
                    <div className='col col-md-4 c3'>
                        © 2024 DevInfinity Inc. All rights reserved |
                        <Link to="/privacy-policy" style={{color:'#8B63E1'}}> Privacy policy</Link>
                    </div>
                    {/* <div className='col col-md-4 c2'>
                        <a href="tel:+91 7042352490"><img src={require('./../assets/img/phone.png')} alt="Phone" className="img-fluid"/> +91 7042352490</a>
                    </div> */}
                   
                </div>
            </div>
            {/* <CookieConsent location="bottom" style={{ background: "#0e1122", textShadow: "2px 2px black" }}
                buttonText="Accept all" declineButtonText="Reject all" cookieName="myAwesomeCookieName3" enableDeclineButton flipButtons expires={999}>
                <strong>We value your privacy</strong><br />
                We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.
            </CookieConsent> */}
        </div>
    );
}

export default Footer;
