import React, { useEffect } from 'react';

export default function BusinessCard() {
  useEffect(() => {
    // Initialize visibility changer component
    if (!window.AFRAME.components['visibility-changer']) {
      window.AFRAME.registerComponent("visibility-changer", {
        init: function () {
          this.el.setAttribute("visible", false);
          this.el.sceneEl.addEventListener('zappar-visible', () => this.el.setAttribute("visible", true));
        }
      });
    }
  }, []);

  const aframeMarkup = `
    <a-scene loading-screen="backgroundColor: #000000">
      <a-entity zappar-permissions-ui id="permissions"></a-entity>
      <a-entity zappar-compatibility-ui id="compatibility"></a-entity>
      <a-assets>
        <img id="card-plain" src="${require('./assets/card/BusinessCardPlain.png')}" alt="Business Card Plain" />
        <img id="web-icon" src="${require('./assets/card/WebLaunch.png')}" alt="Web Icon" />
        <img id="mail-icon" src="${require('./assets/card/mail.png')}" alt="Mail Icon" />
        <img id="phone-icon" src="${require('./assets/card/Phone.png')}" alt="Phone Icon" />
        <a-mixin id="iconProperties" scale="0.3 0.3 0.3" material="alpha-test:0.5"
                 animation="property: scale; to: 0.35 0.35 0.35; dir: alternate; dur: 2000; loop:true;">
        </a-mixin>
      </a-assets>

      <a-camera zappar-camera cursor="rayOrigin: mouse; fuse: false;" raycaster="objects: .collidable"></a-camera>
      
      <a-entity zappar-image="target: ${require('./assets/card/target.zpt')}">
        <a-entity visibility-changer>
          <a-image id="card-background" src="#card-plain" height="2" width="3" position="0 0 0" material="alpha-test:0.5"></a-image>
          <a-entity id="services-list" position="1 0 0.3">
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: Services; color: #FFD700; align: center;"
                      position="-0.5 0.4 0" scale="2 2 2">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: Web / App Development; color: #FFF;"
                      position="0 0.25 0" scale="1.5 1.5 1.5">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: Social Media Branding; color: #FFF;"
                      position="0 0.1 0" scale="1.5 1.5 1.5">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: SEO and Social Media Ads; color: #FFF;"
                      position="0 0 0" scale="1.5 1.5 1.5">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: 3D Animation; color: #FFF;"
                      position="0 -0.1 0" scale="1.5 1.5 1.5">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: Business Analytics & Optimization; color: #FFF;"
                      position="0 -0.25 0" scale="1.5 1.5 1.5">
            </a-entity>
            <a-entity text="font: https://cdn.aframe.io/fonts/Aileron-Semibold.fnt; value: Enterprise Resource Planning; color: #FFF;"
                      position="0 -0.4 0" scale="1.5 1.5 1.5">
            </a-entity>
          </a-entity>
          <a-entity id="menu-items">
            <a-image src="#web-icon" class="collidable" position="-1 0.4 0.3" mixin="iconProperties"
                     link="href: https://devinfinityinc.com/; title: My Homepage;" animation="delay: 1000;">
            </a-image>
            <a-image src="#mail-icon" class="collidable" position="-1 0 0.3" mixin="iconProperties"
                     link="href: mailto:connect@devinfinityinc.com; title: My Facebook;" animation="delay: 2000;">
            </a-image>
            <a-image src="#phone-icon" class="collidable" position="-1 -0.4 0.3" mixin="iconProperties"
                     link="href: tel:123-456-7890p123; title: My Phone;" animation="delay: 3000;">
            </a-image>
          </a-entity>
        </a-entity>
      </a-entity>
    </a-scene>
  `;

  return (
    <div dangerouslySetInnerHTML={{ __html: aframeMarkup }} />
  );
}
