import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Privacy from "./components/Privacy/Privacy";
import Home from "./Home";
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import BusinessCard from "./BusinessCard";
import styles from "./assets/sass/Background.module.scss";

function App() {
  return (
    <div className="App">
       <video autoPlay loop muted className={styles.backgroundVideo}>
        <source src="https://devinfinityvault.s3.ap-south-1.amazonaws.com/videos/background.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Router>
        <Content />
      </Router>
    </div>
  );
}

function Content() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname === "/scan";

  return (
    <>
    
    {!hideHeaderFooter && <Header />}
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/privacy-policy" element={<Privacy />}/>
        <Route path="/scan" element={<BusinessCard />} />
      </Routes>
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

export default App;
